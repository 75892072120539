import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CMSAPIDataCollection,
  CMSAPIDataItem,
  MacroDocumentAttributes,
  MacroRegionAttributes,
  MacroTopicAttributes,
} from "../types/cms";
import { AxiosResponse } from "axios";
import {
  getMacroDocumentsListQueryParamsFields,
  getMacroDocumentsQueryParamsFields,
} from "../utils";
import { fetchDataFromCMS } from "../cms-api";
import { DEAFULT_REPORT_LIST_SIZE } from "../constants";

export const useMacroDocuments = (
  accessToken?: string | null,
  reportListView?: boolean,
) => {
  // TODO: Find ways to combine this hook with useDocuments hook
  const [isLoadingInitial, setIsLoadingInitial] = useState<boolean>(true);
  const [macroDocuments, setMacroDocuments] = useState<
    CMSAPIDataItem<MacroDocumentAttributes>[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [error, setError] = useState<AxiosResponse<any, any> | null>(null);

  const [selectedRegions, setSelectedRegions] = useState<
    MacroRegionAttributes[]
  >([]);
  const [selectedTopics, setSelectedTopics] = useState<MacroTopicAttributes[]>(
    [],
  );

  const queryParams = useMemo(() => {
    const queryFields = reportListView
      ? getMacroDocumentsListQueryParamsFields()
      : getMacroDocumentsQueryParamsFields();
    const params: Record<string, any> = {
      fields: queryFields,
      filters: {
        active: {
          $eq: true,
        },
      },
      sort: ["report_date:desc"],
      pagination: {
        pageSize: DEAFULT_REPORT_LIST_SIZE,
        page: currentPage,
      },
    };
    if (selectedRegions.length) {
      params.filters["region"] = {
        $in: selectedRegions.map((region) => region.region),
      };
    }
    if (selectedTopics.length) {
      params.filters["topic"] = {
        $in: selectedTopics.map((topic) => topic.topic),
      };
    }
    return params;
  }, [currentPage, selectedRegions, selectedTopics]);

  const fetchDocuments = useCallback(async () => {
    const apiEndpoint = `/api/macro-documents`;
    try {
      const { data, error } = await fetchDataFromCMS<
        CMSAPIDataCollection<CMSAPIDataItem<MacroDocumentAttributes>>
      >(apiEndpoint, accessToken, queryParams, {
        method: "get",
      });
      if (error || !data?.data) {
        throw error;
      }

      if (currentPage === 1) {
        setMacroDocuments(data.data);
      } else {
        setMacroDocuments((prev) => [...prev, ...data.data]);
      }
      setHasMore(data.meta.pagination.pageCount > currentPage);
    } catch (fetchError: any) {
      setError(fetchError);
    } finally {
      setIsLoadingInitial(false);
      setIsLoadingMore(false);
    }
  }, [accessToken, currentPage, selectedRegions, selectedTopics]);

  // Effect to handle initial load and reload when filters change
  useEffect(() => {
    if (isLoadingInitial && accessToken) {
      fetchDocuments();
    }
  }, [isLoadingInitial, fetchDocuments, accessToken]);

  // Effect to handle loading more documents
  useEffect(() => {
    if (isLoadingMore && hasMore && accessToken) {
      fetchDocuments();
    }
  }, [isLoadingMore, hasMore, fetchDocuments, accessToken]);

  const loadMoreDocuments = useCallback(() => {
    if (!hasMore || isLoadingMore) return;
    setIsLoadingMore(true);
    setCurrentPage((prevPage) => prevPage + 1);
  }, [hasMore, isLoadingMore]);

  const resetAndFetch = useCallback(() => {
    setIsLoadingInitial(true);
    setMacroDocuments([]);
    setCurrentPage(1);
    setHasMore(true);
  }, []);

  const setSelectedRegionsWithReset = useCallback(
    (regions: MacroRegionAttributes[]) => {
      setSelectedRegions(regions);
      resetAndFetch();
    },
    [resetAndFetch],
  );

  const setSelectedTopicsWithReset = useCallback(
    (topics: MacroTopicAttributes[]) => {
      setSelectedTopics(topics);
      resetAndFetch();
    },
    [resetAndFetch],
  );

  return {
    macroDocuments,
    isLoadingInitial,
    error,
    hasMore,
    isLoadingMore,
    loadMoreDocuments,
    selectedRegions,
    setSelectedRegionsWithReset,
    selectedTopics,
    setSelectedTopicsWithReset,
  };
};
