import React, { useEffect } from "react";
import { useAuthContext } from "../../contexts/authContext";
import { useCommoditiesDocuments } from "../../hooks/commoditiesDocumentsHook";
import {
  CMSAPIDataCollection,
  CMSAPIDataItem,
  CommoditiesDocumentAttributes,
  CommodityAttributes,
} from "../../types/cms";
import useNorthstar from "../../hooks/northstarHook";
import LoadingStatus from "../LoadingStatus";
import ReportList from "../ReportList";
import PageBoundary from "../PageBoundary";
import CommoditiesReportFilter from "../CommoditiesReportFilter";

const CommoditiesInsightsPage = () => {
  const { accessToken, clearIdentity } = useAuthContext();
  const {
    isLoadingInitial,
    hasMore,
    loadMoreDocuments,
    isLoadingMore,
    selectedCommodities,
    setSelectedCommoditiesWithReset,
    commoditiesDocuments,
  } = useCommoditiesDocuments(accessToken, true);

  const {
    data: commoditiesData,
    isLoading: isLoadingCommodities,
    error: errorLoadingCommodities,
  } = useNorthstar<CMSAPIDataCollection<CMSAPIDataItem<CommodityAttributes>>>(
    `/api/commodities`,
    accessToken,
    {
      method: "get",
    },
    {
      fields: ["order", "commodity", "commodity_name"],
      sort: [{ order: "asc" }, { commodity: "asc" }],
    },
  );
  const commodities =
    commoditiesData?.data?.map((commodity) => commodity.attributes) || [];

  const {
    data: latestCommoditiesDocuments,
    isLoading: isLoadingLatestCommoditiesDocuments,
    error: errorLoadingLatestCommoditiesDocuments,
  } = useNorthstar<CMSAPIDataItem<CommoditiesDocumentAttributes>[]>(
    `/api/latest-commodities-documents`,
    accessToken,
    {
      method: "get",
    },
  );

  useEffect(() => {
    let ignore = true;
    if (
      (errorLoadingLatestCommoditiesDocuments?.status === 401 ||
        errorLoadingCommodities?.status === 401) &&
      !ignore &&
      clearIdentity
    ) {
      clearIdentity();
    }
    return () => {
      ignore = false;
    };
  }, [
    errorLoadingLatestCommoditiesDocuments?.status,
    errorLoadingCommodities?.status,
  ]);

  let reportListTemplate;

  if (
    isLoadingCommodities ||
    isLoadingLatestCommoditiesDocuments ||
    isLoadingInitial
  ) {
    reportListTemplate = <LoadingStatus loadingType="reports_loading" />;
  } else if (
    errorLoadingCommodities ||
    errorLoadingLatestCommoditiesDocuments
  ) {
    reportListTemplate = <LoadingStatus loadingType="reports_error" />;
  } else if (!latestCommoditiesDocuments?.length) {
    reportListTemplate = <LoadingStatus loadingType="reports_empty" />;
  } else if (selectedCommodities.length === 0 && latestCommoditiesDocuments) {
    reportListTemplate = (
      <ReportList
        reports={latestCommoditiesDocuments}
        reportType="Commodities"
      />
    );
  } else {
    reportListTemplate = (
      <ReportList
        reports={commoditiesDocuments}
        reportType="Commodities"
        hasMore={hasMore}
        loadMore={loadMoreDocuments}
        isLoadingMore={isLoadingMore}
      />
    );
  }

  return (
    <PageBoundary className="w-full">
      <CommoditiesReportFilter
        commodities={commodities}
        selectedCommodities={selectedCommodities}
        setSelectedCommodities={setSelectedCommoditiesWithReset}
        loadingCommodities={isLoadingCommodities}
      />
      {reportListTemplate}
    </PageBoundary>
  );
};

export default CommoditiesInsightsPage;
