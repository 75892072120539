import React, { useEffect } from "react";
import { useAuthContext } from "../../contexts/authContext";
import { useAudioDeepDives } from "../../hooks/audioDeepDivesHook";
import LoadingStatus from "../LoadingStatus";
import PageBoundary from "../PageBoundary";
import AudioDeepDivesList from "./AudioDeepDivesList";

const AudioDeepDivesInsightsPage = () => {
  const { accessToken, clearIdentity } = useAuthContext();

  const {
    isLoadingInitial,
    hasMore,
    loadMoreAudioDeepDives,
    isLoadingMore,
    audioDeepDives,
    error: errorLoadingAudioDeepDives,
  } = useAudioDeepDives(accessToken);

  useEffect(() => {
    let ignore = true;
    if (
      errorLoadingAudioDeepDives?.status === 401 &&
      !ignore &&
      clearIdentity
    ) {
      clearIdentity();
    }
    return () => {
      ignore = false;
    };
  }, [errorLoadingAudioDeepDives?.status]);

  let audioDeepDivesListTemplate;

  if (isLoadingInitial) {
    audioDeepDivesListTemplate = (
      <LoadingStatus loadingType="reports_loading" />
    );
  } else if (errorLoadingAudioDeepDives) {
    audioDeepDivesListTemplate = <LoadingStatus loadingType="reports_error" />;
  } else if (!audioDeepDives?.length) {
    audioDeepDivesListTemplate = <LoadingStatus loadingType="reports_empty" />;
  } else {
    audioDeepDivesListTemplate = (
      <AudioDeepDivesList
        audioDeepDives={audioDeepDives}
        hasMore={hasMore}
        loadMore={loadMoreAudioDeepDives}
        isLoadingMore={isLoadingMore}
      />
    );
  }

  return (
    <PageBoundary className="w-full">{audioDeepDivesListTemplate}</PageBoundary>
  );
};

export default AudioDeepDivesInsightsPage;
