import React, { useEffect, useState } from "react";
import { AudioDeepDivesAttributes, CMSAPIDataItem } from "../../types/cms";
import { getFormattedDate } from "../../utils";
import { useAuthContext } from "../../contexts/authContext";
import { fetchDataFromCMS } from "../../cms-api";

interface AudioDeepDiveCardProps {
  audioDeepDive: CMSAPIDataItem<AudioDeepDivesAttributes>;
}

const fetchError = `We're unable to load the audio at this time. Please try again later or contact support if the issue continues.`;
const refreshError = `Audio link has expired. Please refresh.`;

const AudioDeepDiveCard: React.FC<AudioDeepDiveCardProps> = ({
  audioDeepDive,
}) => {
  const { accessToken, clearIdentity } = useAuthContext();
  const {
    id,
    attributes: { title, description, report_date, createdAt, audio_file },
  } = audioDeepDive;
  const reportDate = getFormattedDate(report_date || createdAt);

  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [expiryTime, setExpiryTime] = useState<number>(0);
  const [showPlayer, setShowPlayer] = useState<boolean>(false);

  // // Construct the streaming URL directly
  // const streamAudioUrl = `${
  //   process.env.GATSBY_API_URL
  // }/api/audio-deep-dive/stream-audio/${id}-${
  //   audio_file?.data?.attributes?.name || ""
  // }`;

  useEffect(() => {
    const timer = setInterval(() => {
      if (expiryTime && Date.now() > expiryTime) {
        console.log(`Audio link expired for ${title}`);
        setAudioUrl(null);
        setError(refreshError);
      }
    }, 60 * 1000); // Check every minute

    return () => clearInterval(timer);
  }, [expiryTime]);

  // TODO: we should probably use a centralized context to handle all refresh
  const handleFetchUrl = async () => {
    try {
      setError(null);
      const { data, error } = await fetchDataFromCMS<{
        data: { url: string };
      }>(`/api/audio-deep-dive/${id}/playback-url`, accessToken);
      if (error || !data?.data) {
        throw new Error(fetchError);
      }
      setAudioUrl(data.data?.url);
      setExpiryTime(Date.now() + 60 * 60 * 1000); // expirty time is 1 hour
      setShowPlayer(true); // Show the audio player
    } catch (err: any) {
      setError(fetchError);
    }
  };

  const handleShowPlayer = () => {
    if (!audioUrl || Date.now() > expiryTime) {
      handleFetchUrl();
    } else {
      setShowPlayer(true); // Show the audio player if URL is already available
    }
  };

  return (
    <div className="flex flex-col w-full h-full overflow-hidden px-4 py-3 bg-white">
      <div className="flex flex-col flex-grow">
        <div className="text-xl font-semibold text-gray-900 mt-1.5">
          {title}
        </div>
        <div className="mt-2 xs:mt-3 text-sm xs:text-base text-gray-800">
          {reportDate}
        </div>
        <div className="my-2 xs:my-3 pb-1 text-sm text-gray-600">
          {description || ""}
        </div>
      </div>
      <div className="flex-shrink-0">
        {(!audioUrl || Date.now() > expiryTime) && !error && (
          <button
            onClick={handleShowPlayer}
            className="flex items-center pt-1.5 pb-2.5 text-bam-orange hover:underline font-semibold"
          >
            Listen Now
          </button>
        )}
        {showPlayer && audioUrl && Date.now() <= expiryTime && (
          <div className="pt-1.5 pb-2.5">
            <audio id={`audio-player-${id}`} controls>
              <source
                src={audioUrl}
                type={audio_file?.data?.attributes?.mime || "audio/mpeg"}
              />
              <track kind="captions" srcLang="en" label={title} default />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
        {error && (
          <>
            <button
              onClick={handleShowPlayer}
              className="flex items-center pt-1.5 pb-2.5 text-bam-orange hover:underline font-semibold"
            >
              Refresh
            </button>
            <div className="text-red-500 text-sm mt-2">{error}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default AudioDeepDiveCard;
