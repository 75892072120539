import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  CMSAPIDataCollection,
  CMSAPIDataItem,
  MacroDocumentAttributes,
} from "../../types/cms";
import {
  EnvelopeIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { ReportDisclaimer, SunshineSupport } from "../../constants";
import { Disclosure } from "@headlessui/react";
import {
  getElectionPrediction,
  getFormattedDate,
  getMacroDocumentsListQueryParamsFields,
} from "../../utils";
import MarkdownDisplay from "../MarkdownDisplay";
import useNorthstar from "../../hooks/northstarHook";
import { useAuthContext } from "../../contexts/authContext";
import ReportCard from "../ReportCard";
import MacroReportCard from "../MacroReportCard";
import ElectionPredictionCard from "./ElectionPredictionCard";
import ElectionPredictionMobileCard from "./ElectionPredictionMobileCard";

interface MacroReportViewerProps {
  report: MacroDocumentAttributes;
}

const MacroReportViewer: React.FC<MacroReportViewerProps> = ({ report }) => {
  const { accessToken, clearIdentity } = useAuthContext();
  const {
    title,
    report_date,
    summary,
    detailed_insight,
    image_file,
    image_name,
    region,
    topic,
  } = report;
  // temp loading related reports on report viewer. should create a new endpoint for this
  const fields = getMacroDocumentsListQueryParamsFields();
  const {
    data: reportsData,
    isLoading,
    error,
  } = useNorthstar<
    CMSAPIDataCollection<CMSAPIDataItem<MacroDocumentAttributes>>
  >(
    `/api/macro-documents`,
    accessToken,
    {
      method: "get",
    },
    {
      fields,
      filters: {
        active: {
          $eq: true,
        },
        region: {
          $eq: region,
        },
        topic: {
          $eq: topic,
        },
      },
      sort: { report_date: "desc" },
    },
  );
  const relatedDocuments = reportsData?.data?.length
    ? reportsData?.data?.filter(
        (x) => x.attributes.report_id !== report.report_id,
      )
    : [];
  const reportDate = getFormattedDate(report_date);
  const electionPrediction = getElectionPrediction(report?.election_info);
  return (
    <AnimatePresence>
      <motion.div
        key="macro-report-viewer"
        className="flex flex-col w-full mt-3 pt-2 pb-10 px-6 xs:px-8 sm:px-11 md:px-14 lg:px-18 xl:px-20 
      border-t-4 bg-white border-white border-t-bam-alternative-blue"
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          stiffness: 50,
          duration: 0.4,
        }}
      >
        <div className="my-3 pl-2 pr-8 sm:pl-4 sm:pr-12 pt-3 pb-2">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
            {title}
          </h1>
          <div className="mt-3 md:mt-4 xl:mt-5 text-sm xs:text-base text-gray-800">
            {reportDate}
          </div>
          <div className="text-bam-blue mt-2 md:mt-3 xl:mt-4">
            <a
              href={`mailto:${SunshineSupport}`}
              className="flex gap-x-1 font-bebas-neue tracking-wide text-lg"
            >
              <div>
                <span className="sr-only">Email</span>
                <EnvelopeIcon className="w-6 h-6 mt-px" />
              </div>
              Contact
            </a>
          </div>
        </div>
        <div className="mt-1 xl:text-lg">
          <Disclosure defaultOpen={true} as="div">
            {({ open = true }) => (
              <>
                <Disclosure.Button className="flex w-full items-start justify-between text-left py-4 sm:py-5 md:py-6 px-2 sm:px-4 border-t border-t-gray-300 hover:bg-gray-50">
                  <h2 className="text-lg sm:text-xl font-semibold text-gray-800">
                    Summary
                  </h2>
                  <div className="flex">
                    <span className="mt-1 ml-6 flex h-6 items-center">
                      {open ? (
                        <ChevronDownIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronRightIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel
                  as="div"
                  className="pl-2 pr-8 sm:pl-4 sm:pr-12 pt-0.5 md:pt-1 pb-4 xs:pb-5 md:pb-6 lg:pb-7 xl:pb-8 leading-relaxed"
                >
                  <MarkdownDisplay markdownHtml={summary} />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure defaultOpen={true} as="div">
            {({ open = true }) => (
              <>
                <Disclosure.Button className="flex w-full items-start justify-between text-left py-4 sm:py-5 md:py-6 px-2 sm:px-4 border-t border-t-gray-300 hover:bg-gray-50">
                  <h2 className="text-lg sm:text-xl font-semibold text-gray-800">
                    Details
                  </h2>
                  <div className="flex">
                    <span className="mt-1 ml-6 flex h-6 items-center">
                      {open ? (
                        <ChevronDownIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronRightIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel
                  as="div"
                  className="pl-2 pr-8 sm:pl-4 sm:pr-12 pt-0.5 md:pt-1 pb-4 xs:pb-5 md:pb-6 lg:pb-7 xl:pb-8 leading-relaxed"
                >
                  <div className="flex flex-col justify-center items-center">
                    {electionPrediction &&
                      Object.keys(electionPrediction)?.length > 0 && (
                        <div className="w-full mb-4 lg:mb-6">
                          <div className="flex-1 md:hidden">
                            <ElectionPredictionMobileCard
                              prediction={electionPrediction}
                            />
                          </div>
                          <div className="hidden md:flex md:flex-row space-x-12 lg:space-x-14 xl:space-x-16 justify-center">
                            {Object.entries(electionPrediction).map(
                              ([candidate, data]) => (
                                <ElectionPredictionCard
                                  key={candidate}
                                  prediction={data}
                                />
                              ),
                            )}
                          </div>
                          <div className="text-center text-xs xs:text-sm italic text-gray-600">
                            <p className="mt-3 lg:mt-4">
                              * Change over the last 7 days
                            </p>
                            <p className="mt-1">Source: Polymarket</p>
                          </div>
                        </div>
                      )}
                    <div className="flex flex-col items-center align-top mb-4 lg:mb-6">
                      {image_file && (
                        <figure className="h-auto items-center align-middle">
                          <img
                            src={`data:image/png;base64,${image_file} `}
                            alt={image_name}
                            className="w-auto h-auto mb-4"
                          />
                        </figure>
                      )}
                    </div>
                    <MarkdownDisplay markdownHtml={detailed_insight} />
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
        <section className="mt-6">
          <h2 className="sr-only">Disclaimer</h2>
          <div className="p-4 pr-10 text-xxs sm:text-xs tracking-tight leading-snug text-bam-medium-gray">
            {ReportDisclaimer}
          </div>
        </section>
      </motion.div>
      {relatedDocuments.length > 0 && (
        <div key="related-reports" className="flex flex-col w-full mt-8">
          <h2 className="font-bebas-neue text-xl text-bam-blue">
            More Reports on {region}: {topic}
          </h2>
          <div className="grid grid-cols-1 gap-4 mt-3 sm:grid-cols-2 lg:grid-cols-3">
            {relatedDocuments.map((doc) => (
              <div key={doc.id} className="flex flex-col w-full">
                <MacroReportCard document={doc.attributes} reportDetailsView />
              </div>
            ))}
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default MacroReportViewer;
