import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { AudioDeepDivesAttributes, CMSAPIDataItem } from "../../types/cms";
import AudioDeepDiveCard from "./AudioDeepDiveCard";

interface AudioDeepDivesListProps {
  audioDeepDives: CMSAPIDataItem<AudioDeepDivesAttributes>[];
  hasMore?: boolean;
  loadMore?: () => void;
  isLoadingMore?: boolean;
}

const AudioDeepDivesList: React.FC<AudioDeepDivesListProps> = ({
  audioDeepDives,
  hasMore,
  loadMore,
  isLoadingMore,
}) => {
  return (
    <div className="my-6">
      <ul className="mt-3 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-5 xl:gap-4 2xl:gap-5 3xl:gap-6">
        <AnimatePresence>
          {audioDeepDives.map((deepDive) => {
            return (
              <motion.li
                key={deepDive.id}
                className="w-full"
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  stiffness: 50,
                  duration: 0.3,
                }}
              >
                <AudioDeepDiveCard audioDeepDive={deepDive} />
              </motion.li>
            );
          })}
        </AnimatePresence>
      </ul>
      {hasMore && (
        <motion.button
          className="flex items-center w-full mt-2 lg:mt-3 xl:mt-2 2xl:mt-3 3xl:mt-4 py-2"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onViewportEnter={loadMore}
          onClick={loadMore}
          disabled={isLoadingMore}
        >
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="mx-4 text-sm text-gray-600">
            {isLoadingMore ? "Loading..." : "Load more"}
          </span>
          <div className="flex-grow border-t border-gray-300"></div>
        </motion.button>
      )}
    </div>
  );
};

export default AudioDeepDivesList;
