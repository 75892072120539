import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CMSAPIDataCollection,
  CMSAPIDataItem,
  CommoditiesDocumentAttributes,
  CommodityAttributes,
} from "../types/cms";
import { AxiosResponse } from "axios";
import {
  getCommoditiesDocumentsListQueryParamsFields,
  getCommoditiesDocumentsQueryParamsFields,
} from "../utils";
import { fetchDataFromCMS } from "../cms-api";
import { DEAFULT_REPORT_LIST_SIZE } from "../constants";

export const useCommoditiesDocuments = (
  accessToken?: string | null,
  reportListView?: boolean,
) => {
  // TODO: Find ways to combine this hook with useDocuments hook
  const [isLoadingInitial, setIsLoadingInitial] = useState<boolean>(true);
  const [commoditiesDocuments, setCommoditiesDocuments] = useState<
    CMSAPIDataItem<CommoditiesDocumentAttributes>[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [error, setError] = useState<AxiosResponse<any, any> | null>(null);

  const [selectedCommodities, setSelectedCommodities] = useState<
    CommodityAttributes[]
  >([]);

  const queryParams = useMemo(() => {
    const queryFields = reportListView
      ? getCommoditiesDocumentsListQueryParamsFields()
      : getCommoditiesDocumentsQueryParamsFields();
    const params: Record<string, any> = {
      fields: queryFields,
      populate: {
        associated_commodity: {
          fields: ["commodity", "commodity_name"],
        },
      },
      filters: {},
      sort: ["report_date:desc"],
      pagination: {
        pageSize: DEAFULT_REPORT_LIST_SIZE,
        page: currentPage,
      },
    };
    if (selectedCommodities.length) {
      params.filters["commodity"] = {
        $in: selectedCommodities.map((commodity) => commodity.commodity),
      };
    }
    return params;
  }, [currentPage, selectedCommodities]);

  const fetchDocuments = useCallback(async () => {
    const apiEndpoint = `/api/commodities-documents`;
    try {
      const { data, error } = await fetchDataFromCMS<
        CMSAPIDataCollection<CMSAPIDataItem<CommoditiesDocumentAttributes>>
      >(apiEndpoint, accessToken, queryParams, {
        method: "get",
      });
      if (error || !data?.data) {
        throw error;
      }

      if (currentPage === 1) {
        setCommoditiesDocuments(data.data);
      } else {
        setCommoditiesDocuments((prev) => [...prev, ...data.data]);
      }
      setHasMore(data.meta.pagination.pageCount > currentPage);
    } catch (fetchError: any) {
      setError(fetchError);
    } finally {
      setIsLoadingInitial(false);
      setIsLoadingMore(false);
    }
  }, [accessToken, currentPage, selectedCommodities]);

  // Effect to handle initial load and reload when filters change
  useEffect(() => {
    if (isLoadingInitial && accessToken) {
      fetchDocuments();
    }
  }, [isLoadingInitial, fetchDocuments, accessToken]);

  // Effect to handle loading more documents
  useEffect(() => {
    if (isLoadingMore && hasMore && accessToken) {
      fetchDocuments();
    }
  }, [isLoadingMore, hasMore, fetchDocuments, accessToken]);

  const loadMoreDocuments = useCallback(() => {
    if (!hasMore || isLoadingMore) return;
    setIsLoadingMore(true);
    setCurrentPage((prevPage) => prevPage + 1);
  }, [hasMore, isLoadingMore]);

  const resetAndFetch = useCallback(() => {
    setIsLoadingInitial(true);
    setCommoditiesDocuments([]);
    setCurrentPage(1);
    setHasMore(true);
  }, []);

  const setSelectedCommoditiesWithReset = useCallback(
    (commodities: CommodityAttributes[]) => {
      setSelectedCommodities(commodities);
      resetAndFetch();
    },
    [resetAndFetch],
  );

  return {
    commoditiesDocuments,
    isLoadingInitial,
    error,
    hasMore,
    isLoadingMore,
    loadMoreDocuments,
    selectedCommodities,
    setSelectedCommoditiesWithReset,
  };
};
