import React from "react";
import { CommodityAttributes } from "../../types/cms";
import { sortArrayByProperty } from "../../utils";
import AutocompleteDropdown from "../AutocompleteDropdown";
import { Chip } from "@mui/material";

interface CommoditiesReportFilterProps {
  commodities: CommodityAttributes[];
  selectedCommodities: CommodityAttributes[];
  setSelectedCommodities: (commodities: CommodityAttributes[]) => void;
  loadingCommodities: boolean;
}

const CommoditiesReportFilter: React.FC<CommoditiesReportFilterProps> = ({
  commodities,
  selectedCommodities,
  setSelectedCommodities,
  loadingCommodities,
}) => {
  const sortedCommodities = sortArrayByProperty(commodities, "commodity");

  const handleCommodityChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: CommodityAttributes[],
    _reason: string,
  ) => {
    setSelectedCommodities(newValue);
  };

  return (
    <div className="w-full flex justify-center mt-4 xs:mt-5 sm:mt-6">
      <div className="sr-only">Filters</div>
      <div className="w-full lg:w-[64rem]">
        <AutocompleteDropdown
          label="Commodity"
          placeholder="Select a commodity"
          value={selectedCommodities}
          onChange={handleCommodityChange}
          isOptionEqualToValue={(option, value) =>
            option.commodity === value.commodity
          }
          options={sortedCommodities.map((commodity) => ({
            label: commodity.commodity_name || commodity.commodity,
            value: commodity,
          }))}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={`${option.commodity}`}
                // Pass the index directly to getTagProps as per MUI's signature
                {...getTagProps({ index })}
              />
            ))
          }
          loading={loadingCommodities}
        />
      </div>
    </div>
  );
};

export default CommoditiesReportFilter;
