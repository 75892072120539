import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AudioDeepDivesAttributes,
  CMSAPIDataCollection,
  CMSAPIDataItem,
} from "../types/cms";
import { AxiosResponse } from "axios";
import { DEAFULT_REPORT_LIST_SIZE } from "../constants";
import { fetchDataFromCMS } from "../cms-api";

export const useAudioDeepDives = (accessToken?: string | null) => {
  const [isLoadingInitial, setIsLoadingInitial] = useState<boolean>(true);
  const [audioDeepDives, setAudioDeepDives] = useState<
    CMSAPIDataItem<AudioDeepDivesAttributes>[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [error, setError] = useState<AxiosResponse<any, any> | null>(null);

  const queryParams = useMemo(() => {
    const params: Record<string, any> = {
      populate: {
        audio_file: { populate: "*" },
      },
      filters: {},
      sort: [{ report_date: "desc" }, { createdAt: "desc" }],
      pagination: {
        pageSize: DEAFULT_REPORT_LIST_SIZE,
        page: currentPage,
      },
    };
    return params;
  }, [currentPage]);

  const fetchAudioDeepDives = useCallback(async () => {
    const apiEndpoint = `/api/audio-deep-dives`;
    try {
      const { data, error } = await fetchDataFromCMS<
        CMSAPIDataCollection<CMSAPIDataItem<AudioDeepDivesAttributes>>
      >(apiEndpoint, accessToken, queryParams, {
        method: "get",
      });
      if (error || !data?.data) {
        throw error;
      }

      if (currentPage === 1) {
        setAudioDeepDives(data.data);
      } else {
        setAudioDeepDives((prev) => [...prev, ...data.data]);
      }
      setHasMore(data.meta.pagination.pageCount > currentPage);
    } catch (fetchError: any) {
      setError(fetchError);
    } finally {
      setIsLoadingInitial(false);
      setIsLoadingMore(false);
    }
  }, [accessToken, currentPage]);

  // Effect to handle initial load and reload when filters change
  useEffect(() => {
    if (isLoadingInitial && accessToken) {
      fetchAudioDeepDives();
    }
  }, [isLoadingInitial, fetchAudioDeepDives, accessToken]);

  // Effect to handle loading more
  useEffect(() => {
    if (isLoadingMore && hasMore && accessToken) {
      fetchAudioDeepDives();
    }
  }, [isLoadingMore, hasMore, fetchAudioDeepDives, accessToken]);

  const loadMoreAudioDeepDives = useCallback(() => {
    if (!hasMore || isLoadingMore) return;
    setIsLoadingMore(true);
    setCurrentPage((prevPage) => prevPage + 1);
  }, [hasMore, isLoadingMore]);

  return {
    audioDeepDives,
    isLoadingInitial,
    error,
    hasMore,
    isLoadingMore,
    loadMoreAudioDeepDives,
  };
};
