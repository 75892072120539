import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import ReportCard from "../ReportCard";
import { InsightsDocumentAPIItem } from "../../hooks/documentsHook";
import {
  CMSAPIDataItem,
  CommoditiesDocumentAttributes,
  MacroDocumentAttributes,
  ThemesDocumentAttributes,
} from "../../types/cms";
import MacroReportCard from "../MacroReportCard";
import { InsightsTab } from "../../contexts/insightsContext";
import ThemesReportCard from "../ThemesReportCard";
import CommoditiesReportCard from "../CommoditiesReportCard";

interface ReportListProps {
  reports:
    | InsightsDocumentAPIItem[]
    | CMSAPIDataItem<MacroDocumentAttributes>[]
    | CMSAPIDataItem<ThemesDocumentAttributes>[]
    | CMSAPIDataItem<CommoditiesDocumentAttributes>[];
  hasMore?: boolean;
  loadMore?: () => void;
  isLoadingMore?: boolean;
  reportType: InsightsTab;
}

const ReportList: React.FC<ReportListProps> = ({
  reports,
  hasMore,
  loadMore,
  isLoadingMore,
  reportType,
}) => {
  const renderReportCard = (
    report:
      | CMSAPIDataItem<MacroDocumentAttributes>
      | CMSAPIDataItem<ThemesDocumentAttributes>
      | CMSAPIDataItem<CommoditiesDocumentAttributes>
      | InsightsDocumentAPIItem,
  ) => {
    switch (reportType) {
      case "Macro":
        return (
          <MacroReportCard
            document={report.attributes as MacroDocumentAttributes}
          />
        );
      case "Themes":
        return (
          <ThemesReportCard
            document={report.attributes as ThemesDocumentAttributes}
          />
        );
      case "Commodities":
        return (
          <CommoditiesReportCard
            document={report.attributes as CommoditiesDocumentAttributes}
          />
        );
      case "Equities":
      default:
        return (
          <ReportCard
            document={report.attributes}
            associated_ticker={
              (report as InsightsDocumentAPIItem).associated_ticker?.attributes
            }
          />
        );
    }
  };

  return (
    <div className="my-6">
      <ul className="mt-3 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-5 xl:gap-4 2xl:gap-5 3xl:gap-6">
        <AnimatePresence>
          {reports.map((report) => (
            <motion.li
              key={report.id}
              className="w-full"
              layout
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                stiffness: 50,
                duration: 0.3,
              }}
            >
              {renderReportCard(report)}
            </motion.li>
          ))}
        </AnimatePresence>
      </ul>
      {hasMore && (
        <motion.button
          className="flex items-center w-full mt-2 lg:mt-3 xl:mt-2 2xl:mt-3 3xl:mt-4 py-2"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onViewportEnter={loadMore}
          onClick={loadMore}
          disabled={isLoadingMore}
        >
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="mx-4 text-sm text-gray-600">
            {isLoadingMore ? "Loading..." : "Load more"}
          </span>
          <div className="flex-grow border-t border-gray-300"></div>
        </motion.button>
      )}
    </div>
  );
};

export default ReportList;
